import { getCookie, setCookie, deleteCookie } from "@/core/helpers/cookies";

const ID_TOKEN_KEY = "id_token" as string;
const REFRESH_TOKEN_KEY = "refresh_token" as string;
const USER_EMAIL_KEY = "user_email_key" as string;

/**
 * @description get token from cookies
 */
export const getToken = (): string | null => {
  return getCookie(ID_TOKEN_KEY);
};

/**
 * @description save token into cookies
 * @param token: string
 */
export const saveToken = (token: string): void => {
  setCookie(ID_TOKEN_KEY, token, { expires: '30m', path: '/', secure: true });
};

/**
 * @description remove token from cookies
 */
export const destroyToken = (): void => {
  deleteCookie(ID_TOKEN_KEY);
};

/**
 * @description get token from cookies
 */
export const getRefreshToken = (): string | null => {
  return getCookie(REFRESH_TOKEN_KEY);
};

/**
 * @description save token into cookies
 * @param token: string
 */
export const saveRefreshToken = (token: string): void => {
  setCookie(REFRESH_TOKEN_KEY, token, { expires: '1d', path: '/', secure: true });
};

/**
 * @description remove token from cookies
 */
export const destroyRefreshToken = (): void => {
  deleteCookie(REFRESH_TOKEN_KEY);
};

/**
 * @description get email from cookies
 */
export const getUserEmail = (): string | null => {
  return getCookie(USER_EMAIL_KEY);
};

/**
 * @description save email into cookies
 * @param email: string
 */
export const saveUserEmail = (email: string): void => {
  setCookie(USER_EMAIL_KEY, email, { expires: '1d', path: '/', secure: true });
};

/**
 * @description remove email from cookies
 */
export const destroyUserEmail = (): void => {
  deleteCookie(USER_EMAIL_KEY);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getRefreshToken,
  saveRefreshToken,
  destroyRefreshToken,
  getUserEmail,
  saveUserEmail,
  destroyUserEmail,
};
