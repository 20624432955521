<template>
    <!--begin::Toast-->
    <div class="toast-container position-fixed top-0 end-0 p-3">
      <div
        id="kt_docs_toast_stack_container"
        ref="kt_docs_toast_stack_container"
        :style="{ zIndex: 300, boxShadow: 'unset' }"
        class='toast'
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-kt-docs-toast="stack"
        >
        <div
        :class="'border border-2 border-' + type"
        style='border-radius: 0.475rem'
        >
          <div :class="'toast-header bg-light-' + type">
            <span :class="'svg-icon svg-icon-2 svg-icon-' + type + ' me-3'">
              <i :class="'text-' + type + ' fa fa-' + icon"></i>
            </span>
            <strong class="me-auto">{{ title }}</strong>
            <small>Ahora</small>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="toast"
              :aria-label="t('global_common.close')"
            ></button>
          </div>
          <div class="toast-body">{{ message }}</div>
        </div>
      </div>
    </div>
  
    <!--end::Toast-->
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import { useStore } from "vuex";
  import { Toast } from "bootstrap";
  import { useI18n } from "vue-i18n";
  import * as common_es from "@/modules/common/locales/es.json";
  
  export default defineComponent({
    name: "toast-container",
    components: {},
    data: function () {
      return {
        message: "",
        type: "",
        icon: "",
        title: "",
      };
    },
    methods: {
      showToast: function () {
        let toastMessage = this.store.getters.getToastMessage;
        this.message = toastMessage.message;
        this.type = toastMessage.type;
        this.icon = toastMessage.icon;
        this.title = toastMessage.title;
        var toast = new Toast(this.$refs.kt_docs_toast_stack_container);
        toast.show();
      },
    },
    setup() {
      const store = useStore();
      const { t } = useI18n({
        messages: {
          es: { ...common_es },
        },
      });
      return { store, t };
    },
  });
  </script>
  