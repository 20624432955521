enum Actions {
  GET_PROGRAM = 'getProgram',
  FETCH_PROGRAM_INFO = 'fetchProgramInfo',
  LIST_PROGRAMS = 'listPrograms',
  LIST_ACTIVE_PROGRAMS = 'listActivePrograms',
}

enum Mutations {
  SET_PROGRAMS = 'setPrograms',
  SET_PROGRAM_ERRORS = 'setProgramErrors',
  SET_PROGRAM_INFO = 'setProgramInfo',
}

export { Actions, Mutations }
