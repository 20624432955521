<template>
  <router-view />
  <ToastContainer ref="toastContainer" />
</template>

<!-- eslint-disable @typescript-eslint/strict-boolean-expressions -->
<!-- eslint-disable @typescript-eslint/explicit-function-return-type -->
<script lang="ts">
import { defineComponent, nextTick, onMounted } from 'vue'
import { useStore, mapGetters } from 'vuex'
import { useRecaptchaProvider } from 'vue-recaptcha/head'
import { Actions } from '@/store/enums/StoreEnums'
import { Mutations as ToastMutations } from '@/modules/common/toast/store/Enums'
import { initializeComponents } from '@/core/plugins/keenthemes'
import ToastContainer from './components/mutual-code/common/ToastContainer.vue'
import router from './router'

export default defineComponent({
  name: 'App',
  components: {
    ToastContainer
  },
  setup () {
    useRecaptchaProvider()

    const store = useStore()

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(async () => await router.push({ name: 'sign-in' }))
    }

    const onidle = () => {
      signOut()
    }

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      // Not implemented yet
      // store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      void nextTick(() => {
        initializeComponents()
      })
    })

    return {
      store,
      onidle
    }
  },
  watch: {
    getShowMessage: {
      deep: true,
      handler (show) {
        if (show) {
          this.showToastMessage()
        }
      }
    }
  },
  methods: {
    showToastMessage () {
      (this.$refs.toastContainer as any).showToast()
      this.store.commit(ToastMutations.SET_SHOW_MESSAGE, false)
    }
  },
  computed: {
    ...mapGetters(['getShowMessage'])
  }
})

</script>

<style scoped>
@import "@/assets/css/icofont.min.css";
@import "@/assets/css/aos.css";
@import "@/assets/css/style.css";
@import "@/assets/css/responsive.css";

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
