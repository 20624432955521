import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/sign-up",
    component: () => import("@/components/page-layouts/DarkFooter.vue"),
    children: [
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/modules/dashboard/signup/views/SignUpSelect.vue"),
        meta: {
          authRequired: false,
          actionRequired: "none",
        },
      },
      {
        path: "/sign-up/customer",
        name: "customer-sign-up",
        component: () => import("@/modules/dashboard/signup/views/CustomerSignUp.vue"),
        meta: {
          authRequired: false,
          actionRequired: "none",
          orActionsRequired: [],
        },
      },
      {
        path: "/sign-up/agent",
        name: "agent-sign-up",
        component: () => import("@/modules/dashboard/signup/views/AgentSignUp.vue"),
        meta: {
          authRequired: false,
          actionRequired: "none",
          orActionsRequired: [],
        },
      },
    ],
  },
] as Array<RouteRecordRaw>;
