import { Actions as ToastActions, Mutations } from "@/modules/common/toast/store/Enums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class FriendModule extends VuexModule {
  show = false;
  message = "";
  type = "";
  icon = "";
  title = "";

  get getShowMessage() {
    return this.show;
  }

  get getToastMessage() {
    return {
      message: this.message,
      type: this.type,
      icon: this.icon,
      title: this.title,
    };
  }

  @Mutation
  [Mutations.SET_SHOW_MESSAGE](show) {
    this.show = show;
  }

  @Mutation
  [Mutations.SET_MESSAGE](data) {
    this.message = data.message;
    this.type = data.type;
    this.icon = data.icon;
    this.title = data.title;
  }

  // Sends A friend request
  @Action
  async [ToastActions.SHOW_MESSAGE](data) {
    this.context.commit(Mutations.SET_MESSAGE, data);
    this.context.commit(Mutations.SET_SHOW_MESSAGE, true);
  }
}
//const dat = { ...data, ...data };
//this.context.commit(Mutations.SET_AUTH, dat);
