enum Actions {
  // action types
  GET_USER_PROFILE = "getUserProfile",
  CHANGE_ACCOUNT_AVATAR = "changeAccountAvatar",
  CHANGE_ACCOUNT_SETTINGS = "changeAccountSettings",
}

enum Mutations {
  // mutation types
  SET_CUSTOMER_SETTING_ERRORS = "setCustomerSettingErrors",
  SET_USER_PROFILE = "setUserProfile",
  PUT_ACCOUNT_SETTINGS = "putAccountSettings",
}

export { Actions, Mutations };
