/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import ApiService from '@/core/services/ApiService'
import { Actions, Mutations } from './Enums'
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators'

@Module
export default class ProgramModule extends VuexModule {
  programErrors = ''
  programs = []
  programInfo = {}

  get getPrograms () {
    return this.programs
  }

  get getProgramInfo () {
    return this.programInfo
  }

  get getProgramErrors () {
    return this.programErrors
  }

  @Mutation
  [Mutations.SET_PROGRAM_ERRORS] (error) {
    this.programErrors = error
  }

  @Mutation
  [Mutations.SET_PROGRAMS] (programs) {
    this.programs = programs
  }

  @Mutation
  [Mutations.SET_PROGRAM_INFO] (program) {
    this.programInfo = program
  }

  @Action
  async [Actions.LIST_PROGRAMS] () {
    ApiService.setHeader()
    return await ApiService.get('api/portal/programs')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROGRAM_ERRORS, '')
        this.context.commit(Mutations.SET_PROGRAMS, data.data)
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_PROGRAM_ERRORS,
          response.data.message
        )
      })
  }

  @Action
  async [Actions.FETCH_PROGRAM_INFO] (request) {
    ApiService.setHeader()
    return await ApiService.get('api/portal/program/' + request.uuid)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROGRAM_ERRORS, '')
        this.context.commit(Mutations.SET_PROGRAM_INFO, data.data)
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_PROGRAM_ERRORS,
          response.data.message
        )
      })
  }
}
