enum Actions {
  // action types
  SHOW_MESSAGE = "showMessage",
}

enum Mutations {
  // mutation types
  SET_SHOW_MESSAGE = "setShowMessage",
  SET_MESSAGE = "setMessage",
}

export { Actions, Mutations };
