/* eslint-disable @typescript-eslint/explicit-function-return-type */
import ApiService from '@/core/services/ApiService'
import { Actions, Mutations } from './Enums'
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators'
import { handleError } from '@/modules/common/refreshHandler/refreshHandler'

@Module
export default class ContactModule extends VuexModule {
  contactErrors = ''

  get getContactErrors () {
    return this.contactErrors
  }

  @Mutation
  [Mutations.SET_CONTACT_ERROR] (contactErrors) {
    this.contactErrors = contactErrors
  }

  @Action
  async [Actions.SAVE_LEAD] (request) {
    ApiService.setHeader()
    return await ApiService.post('/api/portal/lead', request)
      .then(() => {
        this.context.commit(Mutations.SET_CONTACT_ERROR, '')
      })
      .catch(async ({ response }) => {
        await handleError(
          this,
          response.data,
          Actions.SAVE_LEAD,
          null,
          Mutations.SET_CONTACT_ERROR
        )
      })
  }
}
