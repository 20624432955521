/* eslint-disable @typescript-eslint/explicit-function-return-type */
import ApiService from '@/core/services/ApiService'
import { Actions, Mutations } from './Enums'
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators'
import { handleError } from '@/modules/common/refreshHandler/refreshHandler'

@Module
export default class CountryModule extends VuexModule {
  countries = [] as object[]

  get getCountryList () {
    return this.countries
  }

  @Mutation
  [Mutations.SET_COUNTRY_LIST] (countries) {
    this.countries = [...countries]
  }

  @Action
  async [Actions.FETCH_COUNTRIES] () {
    ApiService.setHeader()
    return await ApiService.get('/api/countries')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COUNTRY_LIST, data.data)
      })
      .catch(async ({ response }) => {
        await handleError(
          this,
          response.data,
          Actions.FETCH_COUNTRIES,
          null,
          null
        )
      })
  }
}
