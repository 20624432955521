import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './plugins/i18n'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { initVeeValidate } from '@/core/plugins/vee-validate'
import * as Sentry from '@sentry/vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import { MotionPlugin } from '@vueuse/motion'
import ApiService from '@/core/services/ApiService'
/* import { VueReCaptcha } from 'vue-recaptcha-v3' */
import { VueRecaptchaPlugin, Checkbox } from 'vue-recaptcha/head'
import JwtService from "@/core/services/JwtService";

const app = createApp(App)
/* app.use(VueReCaptcha, {
  siteKey: '6Ld7ozgpAAAAALaosocS3v8FUXRKixMUzgmlrjqr',
  loaderOptions: { useRecaptchaNet: true }
}) */
app.use(VueRecaptchaPlugin, {
  v2SiteKey: import.meta.env.VITE_APP_RECAPTCHA_V2_SITE_KEY,
  v3SiteKey: import.meta.env.VITE_APP_RECAPTCHA_V3_SITE_KEY
})
app.component('VSelect', vSelect)
app.component('CheckboxCaptcha', Checkbox)
app.use(i18n)
app.use(store)
app.use(router)

ApiService.init(app)
initVeeValidate()

// Extra imports
app.use(MotionPlugin)

// Set up an event listener to receive the token from portal
window.addEventListener('message', (event) => {
  // Ensure the message is from the trusted domain
  const agentsUrl = import.meta.env.VITE_APP_AGENTS_URL;
  const customersUrl = import.meta.env.VITE_APP_CUSTOMERS_URL;

  if (event.origin == agentsUrl || event.origin == customersUrl) {
    const action = event.data.action
    if (action == 'logout') {
      JwtService.destroyToken();
      JwtService.destroyRefreshToken()
      JwtService.destroyUserEmail()
    }
  }
});

if (import.meta.env.VITE_APP_ENVIRONMENT !== 'dev') {
  Sentry.init({
    app,
    dsn: 'https://9cd7e560b63eac70691ac20d33c54c02@o4505324367183872.ingest.us.sentry.io/4506270013456384',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/www\.mutualcode\.io/, /^https:\/\/api\.mutualcode\.io/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

app.mount('#app')
