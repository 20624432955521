import { createStore } from 'vuex'
import { config } from 'vuex-module-decorators'

import AssetModule from '@/store/Asset/Module'
import ContactModule from '@/modules/dashboard/store/Module'
import ProgramModule from '@/modules/program/store/Module'
import CountryModule from '@/modules/common/country/store/Module'
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import Toast from "@/modules/common/toast/store/Module";

config.rawError = true

const store = createStore({
  modules: {
    AssetModule,
    ContactModule,
    CountryModule,
    ProgramModule,
    AuthModule,
    BodyModule,
    Toast,
  }
})

export default store
