enum Actions {
  // action types
  FETCH_IMAGES = 'fetchImages',
}

enum Mutations {
  // mutation types
  SET_IMAGES = 'setImages',
  SET_ASSET_ERRORS = 'setAssetErrors',
}

export { Actions, Mutations }
