/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Mutations } from './Enums'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module
export default class AssetModule extends VuexModule {
  assetErrors = {}

  images = {}

  get getImages () {
    return this.images
  }

  get getAssetErrors () {
    return this.assetErrors
  }

  @Mutation
  [Mutations.SET_IMAGES] (images) {
    this.images = images
  }

  @Mutation
  [Mutations.SET_ASSET_ERRORS] (errors) {
    this.assetErrors = { ...errors }
  }

  /* @Action({ rawError: true })
  [Actions.FETCH_IMAGES]() {
    ApiService.setHeader();
    return ApiService.get("api/public/images")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IMAGES, data.data);
      })
      .catch(async ({ response }) => {
        await handleError(
          this,
          response.data,
          Actions.FETCH_IMAGES,
          null,
          Mutations.SET_ASSET_ERRORS
        );
      });
  } */
}
